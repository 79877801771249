import { Button, Card, Flex, TextAreaField, useAuthenticator } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useState } from "react";
import { LogEntry } from "./models";

export default function CreateEntryForm() {
  const { user } = useAuthenticator((context) => [context.user]);

  const createEntry: React.FormEventHandler<HTMLFormElement> = (ev) => {
    ev.preventDefault();
    if (text === "") {
      return;
    }
    const entry = new LogEntry({
      text: text,
    });
    setDisabled(true);
    DataStore.save(entry).then(() => {
      setText("");
      setDisabled(false);
    }, (err) => { console.error(err) });
  }

  const [text, setText] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);

  return <Card columnStart="1" columnEnd="3" padding="0">
      <form onSubmit={createEntry}>
        <Flex direction="column">
          <TextAreaField
            label={`Hi ${user?.attributes?.name}! What's new?`}
            name="text"
            placeholder="Today I worked on…"
            rows={3}
            value={text}
            onChange={(ev) => setText(ev.target.value)}
            isReadOnly={disabled}
            />
          <Button isDisabled={disabled} type="submit">Create</Button>
        </Flex>
      </form>
    </Card>;
}
