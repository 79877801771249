import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify, DataStore, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Authenticator, Flex, ThemeProvider } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import '@fontsource/inter/variable.css';
import './index.css';

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Hub.listen('auth', async ({ payload }) => {
  if (payload.event === 'signOut' || payload.event === 'signIn') {
    await DataStore.clear();
    await DataStore.start();
  }
});

root.render(
  <React.StrictMode>
    <ThemeProvider colorMode="system">
      <Flex direction="column" padding="1rem">
        <Authenticator>{
          () => (
            <App />
          )
        }</Authenticator>
      </Flex>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
