import { Card, Heading, Loader, Text, useAuthenticator } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { LogEntry } from "./models";
import { PersistentModel } from '@aws-amplify/datastore';
import { DateTime } from 'luxon';

import './LogEntryList.css';

function useQuery<T extends PersistentModel>(
  ...args: Parameters<typeof DataStore.observeQuery<T>>
  ) {
  const [data, setData] = useState<T[]|undefined>(undefined);
  const [isSynced, setIsSynced] = useState<boolean>(false);

  useEffect(() => {
    const subscription = DataStore.observeQuery<T>(...args).subscribe(({ isSynced, items }) => {
      setData(items);
      setIsSynced(isSynced);
    });
    return () => subscription.unsubscribe();
  }, []);

  return { data, isSynced };
}

export default function LogEntryList() {
  const { user } = useAuthenticator();
  const { data: logEntries, isSynced } = useQuery(
    LogEntry,
    entry => entry.owner.eq(user.getUsername()),
    { sort: entry => entry.createdAt("DESCENDING")}
  );

  if (!isSynced) {
    return <Loader />;
  }

  const byDate: Record<string, LogEntry[]> = {};

  for (const entry of logEntries ?? []) {
    if (entry.createdAt == null) continue;
    const entryDate = DateTime.fromISO(entry.createdAt).startOf('day');
    (byDate[entryDate.toISODate()] ??= []).unshift(entry);
  }

  return <div className="log-entry-list">{
    Object.entries(isSynced ? byDate : {}).map(([dateString, entries]) =>
      <Card key={dateString} className="log-entry-item" variation="elevated">
        <DateDisplay date={DateTime.fromISO(dateString)} />
        {entries.map((entry, i) => <div className="log-entry-text" key={i}>{entry.text}</div>)}
      </Card>
    )
  }</div>;
}

const absDateFormat = new Intl.DateTimeFormat('en', {
  dateStyle: 'full',
});

const relDateFormat = new Intl.RelativeTimeFormat('en', {
  numeric: 'auto',
  style: 'narrow'
});

function DateDisplay(props: { date: DateTime }) {
  const today = DateTime.now().startOf('day');
  const dayDiff = props.date.diff(today, 'days').days;

  if (Math.abs(dayDiff) < 2) {
    return <>
      <Heading level={4} textTransform="capitalize">{relDateFormat.format(dayDiff, 'days')}</Heading>
      <Text variation="secondary">{absDateFormat.format(props.date.toJSDate())}</Text>
    </>;
  } else {
    return <Heading level={4}>{absDateFormat.format(props.date.toJSDate())}</Heading>;
  }
}
