import { Button, Card, Divider, Grid, Heading, useAuthenticator } from '@aws-amplify/ui-react';
import CreateEntryForm from './CreateEntryForm';
import LogEntryList from './LogEntryList';

function App() {
  const { signOut } = useAuthenticator((context) => [context.user]);

  return <Grid
    templateColumns="1fr 200px"
    rowGap="0.5rem" columnGap="0.5rem">
    <Heading columnStart="1" columnEnd="2" level={2}>Minilog</Heading>
    <Card columnStart="2" columnEnd="3" textAlign="end">
      <Button onClick={signOut}>Sign Out</Button>
    </Card>
    <CreateEntryForm />
    <Divider columnStart="1" columnEnd="3" />
    <LogEntryList />
  </Grid>;
}

export default App;
